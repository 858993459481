import React from 'react';
import Slider from 'react-slick';
import img1 from '../images/moonna-pnng.png';
import img2 from '../images/moonna-pnng.png';
import img3 from '../images/moonna-pnng.png';
import img4 from '../images/moonna-pnng.png';
import img5 from '../images/moonna-pnng.png';
import img6 from '../images/moonna-pnng.png';

const PhotoSlideshow = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500, // Faster speed (1.5 seconds)
    pauseOnHover: false,  // Do not pause on hover
    pauseOnDotsHover: false, // Do not pause on dots hover
  };

  const images = [img1, img2, img3, img4, img5, img6];

  return (
    <div className="mt-4">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="p-2">
            <img src={img} alt={`Slide ${index + 1}`} className="square-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PhotoSlideshow;
