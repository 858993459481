import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-800 text-black min-h-screen py-12 px-6">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center text-teal-400">
          Welcome to crisp!
        </h1>
        <p className="text-lg mb-6">
          At <strong>crisp</strong>, we’re revolutionizing the fast food experi+ence with a modern twist. Our cloud kitchen concept means we focus on delivering exceptional food right to your doorstep, without the traditional brick-and-mortar constraints.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-teal-300">
          Our Mission
        </h2>
        <p className="text-lg mb-6">
          We’re passionate about bringing you delicious, high-quality fast food made with fresh ingredients and prepared with care. Our goal is to provide you with a convenient and satisfying dining experience that fits seamlessly into your busy lifestyle.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-teal-300">
          What Sets Us Apart
        </h2>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li>
            <span className="font-semibold">Innovative Menu:</span> Our menu features a curated selection of classic favorites and unique creations designed to delight your taste buds. Whether you’re craving a juicy burger, crispy fries, or something a little different, we’ve got you covered.
          </li>
          <li>
            <span className="font-semibold">Fresh Ingredients:</span> We source only the best ingredients to ensure every meal is both tasty and nourishing. Our commitment to quality means you can enjoy fast food that’s as good for your taste buds as it is for your conscience.
          </li>
          <li>
            <span className="font-semibold">Efficient Service:</span> Our cloud kitchen model allows us to focus solely on preparing and delivering your food with speed and precision. No dine-in distractions mean we can get your order to you faster and fresher.
          </li>
          <li>
            <span className="font-semibold">Sustainability:</span> We’re dedicated to reducing our environmental footprint. From eco-friendly packaging to minimizing waste, we’re always looking for ways to make a positive impact.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-teal-300">
          Why Choose Us
        </h2>
        <p className="text-lg mb-6">
          At <strong>crisp</strong>, we believe in doing things differently. Our innovative approach to fast food means you get the best of both worlds: the comfort of familiar favorites and the excitement of new culinary creations, all delivered right to your door.
        </p>

        <p className="text-lg text-center">
          Thank you for choosing us. We can’t wait for you to taste the difference!
        </p>

        <h2 className="text-3xl font-semibold mt-8 text-teal-300">
          Contact Us
        </h2>
        <p className="text-lg mb-6 text-center">
          Have questions or feedback? We’d love to hear from you! Reach out to us at <a href="mailto:[Contact Information]" className="text-teal-400 hover:underline">[Contact Information]</a> and let us know how we can make your experience even better.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
