// src/index.js
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { CartProvider } from './CartContext';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import './App.css'; // Import the main CSS file which now includes Tailwind CSS
import './output.css'; // Import the main CSS file which now includes Tailwind CSS






ReactDOM.render(
  <I18nextProvider i18n={i18n}>

  <CartProvider>
    <App />
  </CartProvider>,
  </I18nextProvider>,

  document.getElementById('root')

);
