import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import KurdishMenu from './components/KurdishMenu';
import ArabicMenu from './components/ArabicMenu';
import EnglishMenu from './components/EnglishMenu';
import Cart from './components/Cart';
import NavbarComponent from './components/Navbar';
import About from './components/about';

import './App.css'; // Import the global CSS file

function App() {
  return (
    <Router>
      <div>
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kurdish" element={<KurdishMenu />} />
          <Route path="/arabic" element={<ArabicMenu />} />
          <Route path="/english" element={<EnglishMenu />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
