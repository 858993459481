import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-black py-6">
      <div className="container mx-auto text-center">
        <div className="flex justify-center space-x-6 mb-4">
          <a href="https://www.facebook.com/profile.php?id=61562727551273" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook className="h-8 w-8 hover:text-blue-500 transition-colors" />
          </a>
          <a href="https://www.instagram.com/crisp.iraq/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram className="h-8 w-8 hover:text-pink-500 transition-colors" />
          </a>
          <a href="https://www.tiktok.com/@crisp.iraq" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
            <FaTiktok className="h-8 w-8 hover:text-black transition-colors" />
          </a>
          <a href="https://wa.me/9647724999905" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
            <FaWhatsapp className="h-8 w-8 hover:text-green-500 transition-colors" />
          </a>
        </div>
        <p className="text-sm mt-4">All rights reserved XOR Company</p>
      </div>
    </footer>
  );
};

export default Footer;
