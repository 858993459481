// src/components/KurdishMenu.js
import React, { useContext, useState } from 'react';
import Card from './Card';
import { CartContext } from '../CartContext';
import CategorySelector from './CategorySelector'; // Import CategorySelector

// Import images
import t16 from '../images/talabat2.png';
import t17 from '../images/cri.jpg';
import special from '../images/special.png';
import pasta from '../images/pasta.jpg';

import rizo from '../images/rizo.jpg';

import cezar from '../images/cezar.jpg';
import wing from '../images/wing.jpg';
import water from '../images/water.jpg';
import sevn from '../images/sevn.jpg';
import cocka from '../images/cocka.jpg';
import mira from '../images/mira.jpg';


import sas from '../images/sas.png';

import t20 from '../images/t20.png';
import trex from '../images/talabat2.png';

const KurdishMenu = () => {
  const { cart, setCart } = useContext(CartContext);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const addToCart = (item) => {
    const existingItem = cart.find(cartItem => cartItem.id === item.id);
    if (existingItem) {
      setCart(cart.map(cartItem =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      ));
    } else {
      setCart([...cart, { ...item, quantity: 1 }]);
    }
    setNotificationMessage(`You added ${item.name} to the cart!`);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const foodItems = [
    { id: 1, name: 'T-REX', description: 'The king of dinosaurs, the king of burgers! A massive flame-grilled beef patty crowned with melted cheddar cheese, caramelized onions, and refreshing pickles, embraced by creamy aioli sauce', price: 5250, image: t16, category: 'Burger' },
    { id: 2, name: 'MONSTER', description: ' A beef patty rich in flavors, dancing with smoked salami slices, caramelized onions,and authentic American BBQ sauce, creating a symphony of delight ', price: 7250, image: trex, category: 'Burger' },
    { id: 3, name: 'GLADIATOR', description: 'Burger of champions! Silky-smooth beef patty challengingrobust cheddar cheese, crispy lettuce, and luxurious Parmesan cheese,in a battle that ends with a resounding victory over taste buds', price: 6250, image: trex, category: 'Burger' },
    { id: 4, name: 'STORM', description: 'A flavor storm! Grilled beef patty, golden onion rings,smoked bacon, and American BBQ sauce come together in an unceasing storm of pleasure ', price: 7250, image: trex, category: 'Burger' },
    { id: 5, name: 'DRAGON', description: 'Fire-breathing dragon! Flaming beef patty, melted cheddar cheese, crispy onions, spicy jalapenos, and chili sauce engage in an epic battle that ends in an explosion of flavors ', price: 6250, image: trex, category: 'Burger' },
    { id: 6, name: 'SAMURAI', description: 'Warrior flavor! Grilled beef patty, cheddar cheese, avocado, bacon, and aioli sauce that awakens your sense', price: 8250, image: trex, category: 'Burger' },
    { id: 7, name: 'TERMINATOR', description: 'The unbeatable burger! Three beef patties,three slices of cheddar cheese, and a mix of fresh vegetables and sauces in an irresistible challenge ', price: 11250, image: trex, category: 'Burger' },
    { id: 8, name: 'APATCHI', description: 'Grilled beef patty, tomatoes, lettuce, pickles, mozzarella steak, and aioli sauce in a blend that combines authenticity with modernity', price: 7250, image: trex, category: 'Burger' },
    { id: 9, name: 'HAMMER', description: ' Burger of strength! Beef patty, seasoned grilled mushrooms,caramelized onions, Swiss cheese, mustard, and pickles in a composition that strikes forcefully on taste buds', price: 7250, image: trex, category: 'Burger' },
    { id: 10, name: 'KNIGHT', description: 'Grilled chicken breast seasoned with special spices, embraced by golden cheddar cheese, fresh lettuce, refreshing pickles, and drizzled with creamy aioli sauce', price: 5250, image: trex, category: 'Burger' },
    { id: 11, name: 'BOOM', description: 'Explosion of flavors! Grilled chicken breast with crispy bacon, melted cheddar cheese, smoky barbecue sauce,tomatoes, lettuce, and pickles, topped with aioli sauce ', price: 6250, image: trex, category: 'Burger' },
    { id: 12, name: 'WARRIOR', description: 'Flavor warrior! Grilled chicken breast with melted cheddar cheese, sweet peppers, shredded carrots, tomatoes, lettuce, spicy jalapeno slices, and irresistible aioli sauce', price: 6250, image: trex, category: 'Burger' },
    { id: 13, name: 'THUNDER', description: 'Are you ready for the storm, Crispy chicken breast covered with cheddar cheese, tomatoes, lettuce, and caramelized onions, complemented with aioli sauce  ', price: 6250, image: trex, category: 'Burger' },
    { id: 14, name: 'NARUTO', description: 'Crispy chicken breast, delicious bacon, cheddar cheese, barbecue sauce, tomatoes, lettuce, pickles, with a touch of aioli ', price: 7250, image: trex, category: 'Burger' },
    { id: 15, name: ' GODZILLA', description: 'Flavor monster! Crispy chicken breast, cheddar cheese, tomatoes, lettuce, pickles, mozzarella sticks, and unmatched aioli sauce', price: 7000, image: trex, category: 'Burger' },
    { id: 16, name: 'NINJA', description: 'Agility and flavor power! Two pieces of crispy chicken breast, chedda cheese, tomatoes, lettuce, smoked turkey salami, and barbecue sauce', price: 8000, image: trex, category: 'Burger' },
    { id: 17, name: 'BULL BURGER', description: 'Sausage lovers, this burger is for you! Crispy sausage, tomatoes, lettuce, pickles, and a special secret sauce ', price: 7000, image: trex, category: 'Burger' },
    { id: 18, name: 'SPIDERMAN', description: 'Embark on a delicious adventure with 4 pieces of crispy chicken, coated in an originalor spicy magical blend, accompanied by golden French fries, a soft bun, a refreshing drink,and delicious coleslaw', price: 7250, image: t17, category: 'crispy Chicken' },
    { id: 19, name: 'IRON MAN', description: 'Gather around a feast of 8 pieces of crispy chicken, coated in an original or spicy blend, with French fries, a bun, 2 drinks, and coleslaw, for an unforgettable experience with friends ', price:14000 , image: t17, category: 'crispy Chicken' },
    { id: 20, name: 'THOR', description: 'Celebrate together with 12 pieces of crispy chicken, coated in an original or spicy blend, along with family-sized French fries, a bun,a family drink, and coleslaw, in an irresistible family meal ', price: 21000, image: t17, category: 'crispy Chicken' },
    { id: 21, name: 'HULK', description: 'Satisfy your hunger with 24 pieces of crispy chicken, coated in an original or spicy blend, with large family-sized French fries, a bun, a family drink,and coleslaw, in a legendary meal that suits every family membe', price: 39000, image: t17, category: 'crispy Chicken' },
    { id: 22, name: 'MONALISA', description: 'Vegetable pasta', price: 10000, image: special, category: 'special dishes' },
    { id: 23, name: 'CLEOPATRA', description: 'Vegetable pasta', price: 9000, image: special, category: 'special dishes' },
    { id: 24, name: 'BBQ HOUSE', description: 'Dive into the pleasure of harmoniously grilled chicken with smoky barbecue sauce, on a layer of melted cheese and onion, atop crispy crunchy dough', price: 6750, image: t20, category: 'Pizza' },
    { id: 25, name: 'DELUXE HOUSE', description: 'Discover a unique blend of flavors with rich pepperoni slices,olives, onion, green pepper, and mushrooms,on a layer of melted cheese, on crispy, crunchy dough', price: 7750, image: t20, category: 'Pizza' },
    { id: 26, name: 'RANCHY HOUSE', description: 'Enjoy an authentic experience with creamy ranch sauce, tender grilled chicken, and a special cheese blend,on a soft white pizza dough ', price: 6750, image: t20, category: 'Pizza' },
    { id: 27, name: 'CHEESE HOUSE', description: 'Cheese lovers, this pizza is for you! An irresistible mix of different cheeses that meltin your mouth with every bite, on a golden crispy dough ', price: 6250, image: t20, category: 'Pizza' },
    { id: 28, name: 'PICCANTE HOUSE', description: ' Enjoy a mix of spicy pepperoni and melted cheese, on crispy crunchy dough, in an unforgettable experience for heat lovers ', price: 7500, image: t20, category: 'Pizza' },
    { id: 29, name: 'WHITE HOUSE', description: 'Immerse yourself in the smoothness of creamy Alfredo sauce, with a blend of tender grilled chicken and mushrooms, on a layer of melted cheese, on a delicate white pizza dough', price: 7500, image: t20, category: 'Pizza' },
    { id: 30, name: 'VEGGIE HOUSE', description: 'Discover a wonderful blend of fresh vegetables, olives, onion, green pepper, and mushrooms, with a layer of melted cheese, on crispy crunchy dough', price: 7000, image: t20, category: 'Pizza' },
    { id: 31, name: 'BOLOGNESE', description: 'Dive into the classic world of pasta, with rich Bolognese sauce made with minced meat and tomatoes, in an unforgettable experience', price: 6250, image: pasta, category: 'Pasta' },
    { id: 32, name: ' CHICKEN ALFREDO', description: 'Enjoy a mix of tender chicken with rich creamy Alfredo sauce, in an irresistible pasta dish', price: 6250, image: pasta, category: 'Pasta' },
    { id: 33, name: 'FETTUCCINE', description: 'Discover the rich flavor of mushrooms with creamy white sauce,in the famous Fettuccine pasta dish.', price: 7250, image: pasta, category: 'Pasta' },
    { id: 34, name: 'LASAGNA', description: 'Indulge yourself with layers of minced meat, cheese, and béchamel sauce, in an authentic Italian lasagna dish', price: 8250, image: pasta, category: 'Pasta' },
    { id: 35, name: 'VEGGIE PASTA', description: 'Enjoy a healthy and delicious dish of fresh vegetables with tasty tomato sauce', price: 7750, image: pasta, category: 'Pasta' },
    { id: 36, name: 'SPRING RIZZO', description: 'Original KFC rice with vegetables and Crispy chicken strips, combining enjoyable crunchiness with delicious juiciness in every bite', price: 6250, image: rizo, category: 'rizo' },
    { id: 37, name: 'MR. SAUSAGE RIZZO', description: 'Original KFC rice with raisin and Special sauce for sausage lovers,  creating a harmonious fusion for lovers of rich and diverse flavors', price: 8250, image: rizo, category: 'rizo' },
    { id: 38, name: 'DEVIL RIZZO', description: 'Original KFC rice with crispy chicken strips and Spicy jalapeno, providing a perfect blend of delicious chicken texture and exciting spices', price: 6250, image: rizo, category: 'rizo' },
    { id: 39, name: 'SMOKE RIZZO', description: 'Original KFC rice with healthy chicken strips grilled on flame, offering a healthy and tasty experience enhanced by rich and distinctive barbecue flavor', price: 7000, image: rizo, category: 'rizo' },
    { id: 40, name: 'LANDMARK RIZZO', description: 'Original KFC rice with healthy chicken strips and your choice of sauce,allowing you to personalize your dining experience with a variety of flavors', price: 7000, image: rizo, category: 'rizo' },
    { id: 41, name: 'FATTOUSH SALAD', description: 'Tomato, Cucumber, Green pepper, Onion, Lemon, Lettuce and Pomegranate molasses,  offering an authentic experience', price: 4750, image: cezar, category: 'salads' },
    { id: 42, name: 'ROKA SALAD', description: 'Cherry tomato, Arugula, Walnuts, Lemon, Olive oil and Mushrooms, healthy salad with harmoniously blended flavors.', price: 5500, image: cezar, category: 'salads' },
    { id: 43, name: 'CAESAR SALAD', description: 'Chicken, Tomato, Lettuce, Croutons, Parmesan cheese and Caesar sauce, a rich and satisfying experience of  flavors', price: 6000, image: cezar, category: 'salads' },
    { id: 44, name: 'GREEK SALAD', description: 'Tomato, Feta yogurt, Black olives, Green pepper and lettuce,  adding a refreshing and distinctive flavor to the salad that  is worth experiencing', price: 6250, image: cezar, category: 'salads' },
    { id: 45, name: 'JURASSIC SALAD', description: 'Pieces of fresh avocado with tomatoes, Lettuce and Nuts creating a unique and nutrient-rich experience.', price: 6500, image: cezar, category: 'salads' },
    { id: 46, name: 'BAD BOYS', description: '8 pieces of golden crispy chicken, served with a variety of delicious sauces for dipping', price: 4250, image: wing, category: 'side dishes' },
    { id: 47, name: 'SUNRISE FRISE', description: 'Golden French fries, crispy on the outside and tender on the inside, a classic irresistible experience', price: 2250, image: wing, category: 'side dishes' },
    { id: 48, name: 'SUPER HAT TRICK', description: '4 golden mozzarella sticks, crispy and enjoyable, perfect for sharing with friends', price: 4250, image: wing, category: 'side dishes' },
    { id: 49, name: 'VENUS FRISE', description: 'Golden fries covered with melted cheddar cheese, crispy chicken pieces, and creamy cheese sauce, an irresistible mix of crispiness and creaminess', price: 2250, image: wing, category: 'side dishes' },
    { id: 50, name: 'ONION CROWN', description: '5 Pieces of crispy onion rings as you lik', price: 2250, image: wing, category: 'side dishes' },
    { id: 51, name: 'BUFFALO WINGS', description: '6 grilled chicken wings coated with spicy and distinctive buffalo sauce, an unforgettable experience for spice lovers', price: 5250, image: wing, category: 'side dishes' },
    { id: 52, name: 'BBQ WINGS', description: ' 6 grilled chicken wings seasoned with barbecue spices, coated with smoky barbecue sauce, a blend of sweetness and smokiness ', price: 5250, image: wing, category: 'side dishes' },
    { id: 53, name: 'HOT WINGS', description: ' For thrill seekers! 6 grilled chicken wings coated with spicy chili sauce, an irresistible challenge for lovers of fiery flavors', price: 5250, image: wing, category: 'side dishes' },
    { id: 55, name: 'Honey mustard sauce', description: 'Honey mustard sauce', price: 500, image: sas, category: 'special souces' },
    { id: 56, name: 'Cheddar sauce', description: 'Cheddar sauce', price: 500, image: sas, category: 'special souces' },
    { id: 57, name: 'Ketchup saucee', description: 'Ketchup saucee', price: 250, image: sas, category: 'special souces' },
    { id: 58, name: 'Mixed Sauce', description: 'Mixed Sauce', price: 500, image: sas, category: 'special souces' },
    { id: 59, name: 'Ranch sauce', description: 'Ranch sauce', price: 250, image: sas, category: 'special souces' },
    { id: 60, name: 'BBQ sauce', description: 'BBQ sauce', price: 250, image: sas, category: 'special souces' },
    { id: 61, name: 'Mayonnaise sauce', description: 'Mayonnaise sauce', price: 250, image: sas, category: 'special souces' },
    { id: 62, name: 'Garlic sauce', description: 'Garlic sauce', price: 250, image: sas, category: 'special souces' },
    { id: 63, name: 'Buffalo sauce', description: 'Buffalo sauce', price: 500, image: sas, category: 'special souces' },
    { id: 64, name: 'Hot chili sauce', description: 'Hot chili sauce', price: 500, image: sas, category: 'special souces' },
    { id: 65, name: 'COCA COLA', description: 'COCA COLA', price: 750, image: cocka, category: 'drinks' },
    { id: 66, name: 'SEVEN UP', description: 'SEVEN UP', price: 750, image: sevn, category: 'drinks' },
    { id: 67, name: 'MIRINDA ORANGE', description: 'MIRINDA ORANGE', price: 750, image: mira, category: 'drinks' },
    { id: 68, name: 'WATER', description: 'WATER', price: 500, image: water, category: 'drinks' },
 
    
  
   
   
  
    

    
  ];

  const filteredItems = foodItems.filter(item =>
    selectedCategory === 'All' || item.category === selectedCategory
  );

  return (
    <div className="container mx-auto mt-8">
      {showNotification && (
        <div className="bg-red border border-green-400 text-green-700 px-4 py-3 rounded relative mt-3">
          {notificationMessage}
        </div>
      )}
      <CategorySelector selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      <div className="flex flex-wrap -m-2">
        {filteredItems.map(item => (
          <Card key={item.id} item={item} addToCart={addToCart} />
        ))}
      </div>
    </div>
  );
};

export default KurdishMenu;
