// src/components/CategorySelector.js
import React from 'react';
import SwipeableViews from 'react-swipeable-views';

const categories = [

  { name: 'All' },
  { name: 'Burger' },
  { name: 'crispy Chicken' },
  { name: 'special dishes'},
  { name: 'Pizza'},
  { name: 'Pasta'},
  { name: 'rizo' },
  { name: 'salads' },
  { name: 'side dishes' },
  { name: 'special souces' },
  { name: 'drinks' },
];

const CategorySelector = ({ selectedCategory, setSelectedCategory }) => {
  const chunkedCategories = [];
  const chunkSize = 5;

  // Chunk categories into groups of 5
  for (let i = 0; i < categories.length; i += chunkSize) {
    chunkedCategories.push(categories.slice(i, i + chunkSize));
  }

  return (
    <SwipeableViews enableMouseEvents>
      {chunkedCategories.map((chunk, index) => (
        <div key={index} className="flex justify-around p-2 bg-gray-800 rounded-lg">
          {chunk.map((category) => (
            <div
              key={category.name}
              className={`flex flex-col text-white items-center cursor-pointer p-2 rounded-md transition duration-200 hover:border-red-500 ${selectedCategory === category.name ? 'bg-red-500' : 'bg-gray-700'}`}
              onClick={() => setSelectedCategory(category.name)}
            >
              <span className="text-center">{category.name}</span>
            </div>
          ))}
        </div>
      ))}
    </SwipeableViews>
  );
};

export default CategorySelector;
