// src/components/Modal.js
import React from 'react';

const Modal = ({ show, onClose, item }) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg overflow-hidden shadow-xl w-full max-w-lg sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl p-4 mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">{item.name}</h2>
          <img className="w-full h-48 object-cover mb-4" src={item.image} alt={item.name} />
          <p className="text-black text-base mb-4">{item.description}</p>
          <p className="text-black text-base mb-4">Price: {item.price}</p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
