// src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from './footer';


const Home = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="container mx-auto text-center mt-20 " >
      <div className="grid grid-cols-1 md:grid-cols-1 gap-2 items-center" >
       
        <div className="md:col-span-1 p-4 justify-items-center " >
          
          
          <div className="text-black col-span-2 border-2 rounded-full p-4">
           
            <Link
              to="/kurdish"
              className="text-2xl py-2 px-4 rounded-full"
              onClick={() => changeLanguage('ku')}
            >
              کوردی
            </Link>
          </div>
        </div>
       
        <div className="col-span-1 md:col-span-1 p-4">
          <div className="text-black border-2 rounded-full p-4">
            <Link
              to="/arabic"
              className="text-2xl text-black py-2 px-4 rounded-full"
              onClick={() => changeLanguage('ar')}
            >
              عربی
            </Link>
          </div>
        </div>
        <div className="col-span-1 md:col-span-1 p-4">
          <div className="text-black border-2 rounded-full p-4 " >
            <Link
              to="/english"
              className="text-2xl text-black py-2 px-4 rounded-full"
              onClick={() => changeLanguage('en')}
            >
              English
            </Link>
          </div>
        </div>
      </div>
<Footer />
    </div>
  );
};

export default Home;
