// src/components/Card.js
import React, { useState } from 'react';
import Modal from './Modal';

const Card = ({ item, addToCart }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="text-red w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
        <div className="bg-white text-red border rounded-xl overflow-hidden shadow-lg h-full">
          <img
            className="w-full h-48 object-cover cursor-pointer"
            src={item.image}
            alt={item.name}
            onClick={openModal}
          />
          <div className="p-4 flex flex-col justify-between h-full">
            <div>
              <h2
                className="font-bold text-red text-xl mb-2 cursor-pointer"
                onClick={openModal}
              >
                {item.name}
              </h2>
              <p className="text-black text-base">Price: ${item.price}</p>
              <button
                className="bg-red text-white hover:bg-blue-700 text-white font-bold py-2 px-2 sm:px-4 sm:py-2 rounded mt-4 w-full sm:w-auto"
                onClick={() => addToCart(item)}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onClose={closeModal} item={item} />
    </>
  );
};

export default Card;
