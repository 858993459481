// src/components/Cart.js
import React, { useContext, useState } from 'react';
import { CartContext } from '../CartContext';
import { useTranslation } from 'react-i18next';

const Cart = () => {
  const { cart, setCart } = useContext(CartContext);
  const [address, setAddress] = useState('');


  const { i18n } = useTranslation();
  const { t } = useTranslation();


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const increaseQuantity = (item) => {
    setCart(cart.map(cartItem =>
      cartItem.id === item.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    ));
  };

  const decreaseQuantity = (item) => {
    if (item.quantity === 1) {
      setCart(cart.filter(cartItem => cartItem.id !== item.id));
    } else {
      setCart(cart.map(cartItem =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: Math.max(cartItem.quantity - 1, 1) }
          : cartItem
      ));
    }
  };

  const subtotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  const formatOrderMessage = () => {
    const itemsMessage = cart.map(item => `${item.quantity} x ${item.name}`).join('\n');
    return `Order Details:\n${itemsMessage}\n\nSubtotal: $${subtotal.toFixed(2)}\n\nAddress: ${address}`;
  };

  const handleSubmitOrder = () => {
    const message = encodeURIComponent(formatOrderMessage());
    window.open(`https://wa.me/9647724999905?text=${message}`, '_blank');
  };

  return (
    <div className="container mx-auto mt-3">
      <div className="flex flex-wrap -m-2">
        {cart.map(item => (
          <div key={item.id} className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <div className="bg-white text-red border rounded-md overflow-hidden shadow-lg h-full">
              <img className="w-full h-48 object-cover" src={item.image} alt={item.name} />
              <div className="p-4">
                <h2 className="font-bold text-red text-xl mb-2">{item.name}</h2>
{/*                 <p className="text-black text-base">{item.description}</p>
 */}                <p className="text-black text-base">Price: ${item.price}</p>
                <p className="text-black text-base">Quantity: {item.quantity}</p>
                <div className="flex justify-between mt-4">
                  <button
                    className="bg-red hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => increaseQuantity(item)}
                  >
                    +
                  </button>
                  <button
                    className="bg-red hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => decreaseQuantity(item)}
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-3 text-right">
        <h4 className="text-xl">Subtotal: ${subtotal.toFixed(2)}</h4>
      </div>
      <div className="mt-3">
        <div className="max-w-md mx-auto">
          <form>
            <div className="mb-4">
              <label className="block  text-red-200 text-sm font-bold mb-2" htmlFor="formAddress">
              {t('navbar.address')}
              </label>
              <input
                className="shadow text-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="formAddress"
                type="text"
                placeholder="Enter your address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <button
              className="bg-red hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              onClick={handleSubmitOrder}
            >
      {t('navbar.submit')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Cart;
