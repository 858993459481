import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/prtaqalli.png';
import PhotoSlideshow from './PhotoSlideshow';
const Navbar = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-4 py-2 px-4 rounded-lg bg-transparent">
      <nav className="flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" style={{ width: '133px', height: '48px' }} className="rounded" />
        </div>
       
        {/* No more dropdown on small screens */}
        <div className="flex space-x-4">
          <Link to="/about" className="navlink text-white hover:text-gray-600">
            {t('navbar.about')}
          </Link>
          <Link to="/cart" className="navlink text-white hover:text-gray-600">
            {t('navbar.cart')}
          </Link>
          <Link to="/" className="navlink text-white hover:text-gray-600">
            {t('navbar.home')}
          </Link>
        </div>
      </nav>

      <PhotoSlideshow />
    </div>
  );
};

export default Navbar;
